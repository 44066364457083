/**
 * corporate\html\js\corp-2021\src\login-auth.js
 * 
 * Uses shopraise-login module
 * Handlers for login / logout menu
 */

import shopraise, { GoogleLogin } from '../../shopraise-login/shopraise-login.esm'
import axios from 'axios'
import Swal from 'sweetalert2'

const shopraiseLogin = shopraise.login

/**
 * Classes that are hooked with login handlers
 * 
 * .logout-button
 *    Clicks will logout the user **from ShopRaise only**
 *    Any 3rd party logins remain active per each platforms rules
 *    Ex: a google login that is logged out - the google auth remains in effect
 * 
 * .login-open-modal
 *    Clicks will open the login modal
 * 
 * .login-close-modal
 *    Clicks will close the login modal
 * 
 * .google-login-button
 * .facebook-login-button
 * .shopraise-login-button
 * .forgot-password-button
 * .no-click
 */


// wrap in IIFE
;(function (d) {
   
  d.querySelectorAll('.no-click').forEach( el => {
    el.addEventListener('click', (e) => {
      e.preventDefault()
      return false
    })
  })

  d.querySelectorAll('.logout-button').forEach( el => {
    el.addEventListener('click', e => {
      shopraiseLogin.logout()
      .then( result => {
        window.location.reload()
      })
      .catch(err => {
        console.log(err)
      })
      e.preventDefault()
      return false
    })
  })

  d.querySelectorAll('.login-open-modal').forEach( el => {
    el.addEventListener('click', e => {
      // reset inputs
      const email = document.getElementById('edtLogin')
      const password = document.getElementById('edtPassword')
      const keepLoggedIn = document.getElementById('ckKeepLoggedIn')
      if (email) {
        email.value = ''
        email.classList.remove('error')
        email.placeholder = 'Email Address'
      }
      if (password) {
        password.value = ''
        password.classList.remove('error')
        password.placeholder = 'Password'
      }
      if (keepLoggedIn) keepLoggedIn.checked = true
      
      // show modal
      const body = document.getElementsByTagName('body')[0]
      const loginModal = d.getElementById('login-modal')
      const overlay = loginModal.closest('.modal-overlay')
      body.classList.add('modal-open')
      overlay.classList.add('show')
      e.preventDefault()
      return false
    })
  })

  d.querySelectorAll('.login-close-modal').forEach( el => {
    el.addEventListener('click', e => {
      const el = e.target
      const closeOptions = el.dataset.closeOptions
      const body = document.getElementsByTagName('body')[0]
      const loginModal = d.getElementById('login-modal')
      const overlay = loginModal.closest('.modal-overlay')
      overlay.classList.remove('show')
      body.classList.remove('modal-open')
      // TODO: clear entry fields to start fresh next time
      if (closeOptions !== 'noPreventDefault') {
        // for links don't navigate
        e.preventDefault()
        return false;
      } else {
        return true;
      }
    })
  })

  /*
  d.querySelectorAll('.google-login-button').forEach( el => {
    el.addEventListener('click', e => {
      console.log('Google login clicked')
      const keepLoggedIn = document.getElementById('ckKeepLoggedIn').checked || false
      const params = (keepLoggedIn)
        ? { keepLoggedIn }
        : {}
      shopraiseLogin.login('google', params)
      .then(googleUser => {
        // user is gapi.auth2.BasicProfile
        console.log('google login result:', googleUser)
        window.location.reload()
      })
      .catch(err => {
        console.log(err)
      })
      e.preventDefault()
      return false
    })
  })
  */

  /**
   * Google login
   * 
   * Older "Sign in with Google" Javascript SDK has DEPRECATED
   * The updated shopraise.GoogleLogin module throws the CustomEvent
   * "googleLogin:success" if Google logs the user in and calls the
   * callback function inside shopraise.GoogleLogin module.
   * 
   * See: https://developers.google.com/identity/gsi/web/reference/js-reference
   */
  d.addEventListener('googleLogin:success', e => {
    console.log('googleLogin:success event =', e)
    window.location.reload()
  })

  GoogleLogin.getLoginPromise().then(googleUser => {
    console.log('GoogleLogin::promise resolved to', googleUser)
  })
  

  d.querySelectorAll('.facebook-login-button').forEach( el => {
    el.addEventListener('click', e => {
      console.log('Facebook login clicked')
      const keepLoggedIn = document.getElementById('ckKeepLoggedIn').checked || false
      const params = (keepLoggedIn)
        ? { keepLoggedIn }
        : {}
      shopraiseLogin.login('facebook', params)
        .then(response => {
          console.log('Facebook Login:', response)
          window.location.reload()
        })
        .catch(err => {
          console.error('Facebook Login:', err)
        })
        e.preventDefault()
        return false
      })
  })

  d.querySelectorAll('.shopraise-login-button').forEach( el => {
    el.addEventListener('click', e => {
      console.log('ShopRaise login clicked')
      // edtLogin, edtPassword
      const edtEmail = document.getElementById('edtLogin')
      const email = edtEmail.value || ''
      const edtPassword = document.getElementById('edtPassword')
      const password = edtPassword.value || ''
      const keepLoggedIn = document.getElementById('ckKeepLoggedIn').checked || false

      edtEmail.classList.remove('error')
      edtPassword.classList.remove('error')

      if (email && password) {
        const params = { email, password }
        if (keepLoggedIn) params['keepLoggedIn'] = true
        shopraiseLogin.login('shopraise', params)
          .then(response => {
            console.log('Shopraise Login:', response)
            const success = response !== false
            if (success) {
              window.location.reload()
            } else {
              alert('Login unsuccessful.\nPlease try again')
            }
          })
          .catch(err => {
            console.error('Shopraise Login:', err)
          })
      } else {
        if (email === '') {
          edtEmail.classList.add('error')
          edtEmail.placeholder = 'Email Address missing!'
        }
        if (password === '') {
          edtPassword.classList.add('error')
          edtPassword.placeholder = 'Password missing!'
        }
      }
      e.preventDefault()
      return false
    })
  })

  /*
  d.querySelectorAll('.forgot-password-button').forEach( el => {
    el.addEventListener('click', e => {
      console.log('Forgot password clicked')
      const email = document.getElementById('edtLogin').value || ''
      if (!email) {
        Swal.fire('Email missing', 'Please provide an email for the<br>account you wish to reset.', 'error')
      } else {
        shopraiseLogin.forgotPassword(email)
          .then(response => {
            console.log('ShopRaise forgot password', response);
            if (response) {
              // true = success
              Swal.fire('Check your inbox', 'A password reset email has been sent<br>Please check your inbox', 'success')
            } else {
              Swal.fire('Oops', 'Apologies. Something went wrong.', 'error')
            }
          })
          .catch(error => {
            console.log('ShopRaise forgot password: error =', error);
            Swal.fire('Server says, "Oops"', 'Apologies. Something went wrong.', 'error')
          })

      }
      e.preventDefault()
      return false;
    })
  })
  */

}(document))

