/* src\shopraise-login.js */

/**
 * ShopRaise login
 * 
 * user / password login via ajax returning
 *  - ~no setup()
 *  - ~no UI
 *  - @param email    {string} login name / email
 *  - @param password {string} password
 *
 *  - @returns
 *    - success / failure
 *    - promise
 *    - execute callback
 */

class NativeLoginClass {
  
  setup() {
    // none needed 
    return true
  }

  /**
   * 
   * @param {object} params
   *    {
   *      emailAddress    {string} required
   *      [
   *          password    {string}
   *        | facebookId  {string}
   *        | googleId    {string}
   *      ] required
   *      keepLoggedIn    {boolean} defaults to false
   *    }
   * @returns supporterToken?????
   */
  login(args = {}) {
    // TODO: use Promise
    // TODO: use fetch API
    // TODO: handle vendor specific options
    // params: emailAddress, password, facebookId, googleId, keepLoggedIn
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams({
        command: 'login',
        emailAddress: args.email,
      });
      if (args.hasOwnProperty('password')) params.append('password', args.password);
      if (args.hasOwnProperty('googleId')) params.append('googleId', args.googleId);
      if (args.hasOwnProperty('facebookId')) params.append('facebookId', args.facebookId);
      if (args.hasOwnProperty('keepLoggedIn')) params.append('keepLoggedIn', args.keepLoggedIn);
      const url = 'https://shopraise.com/login_auth.php?'+params;
      console.log('shopraise-login.login:: url =', url);
      fetch(url)
        .then(response => response.json())
        .then(data => {
          console.log('shopraise-login.login:: data =', data);
          resolve(data);
        })
        .catch(error => {
          console.error('shopraise-login.login:: error =', error);
          reject(error);
        });
    })
    // return (email && password)
    //   ? Promise.resolve()
    //   : Promise.reject()
  }

  // autoLogin() {
  //   return Promise.resolve()
  // }

  logout() {
    return new Promise((resolve, reject) => {
      const url = 'https://shopraise.com/login_auth.php?command=logout';
      fetch(url)
        .then(response => response.json())
        .then(data => { resolve(data); })
        .catch(error => { reject(error); });
    })
  }

  forgotPassword(email = null) {
    return new Promise((resolve, reject) => {
      if (email === null) {
        reject('Email missing');
      } else {
        const params = new URLSearchParams({
          command: 'forgotPassword',
          emailAddress: email,
        });
        const url = 'https://shopraise.com/login_auth.php?'+params;
        console.log('shopraise-login.forgotPassword:: url =', url);
        fetch(url)
          .then(response => response.json())
          .then(data => { resolve(data); })
          .catch(error => { reject(error); });
      }
    })
  }
  
} // class NativeLoginClass

const ShopraiseLogin = new NativeLoginClass;/* src\google-login.js */

// import ShopraiseLogin from "./shopraise-login";

/**
 * Google login
 * 
 * using google login sdk
 *  - ~setup() - injects sdk if missing, sets up ids etc.
 *  - UI - google handles login UI
 *  - @returns
 *    - success / failure
 *    - promise
 *    - execute callback
 * 
 * @see https://developers.google.com/identity/sign-in/web
 * @see https://developers.google.com/identity/sign-in/web/reference
 * @see https://developers.google.com/identity/sign-in/web/listeners
 * @see https://developers.google.com/identity/gsi/web/guides/client-library
 * @see https://github.com/rmartide/vue-google-login/blob/master/src/GoogleAuth.js
 */



class GoogleLoginClass {

  /**
   * Needed because of the Google login sdk changes
   * see: 
   */
  static loginPromise;
  static loginResolver;
  static loginRejecter;

  // hold a copy of the decoded JWT response
  credential;
  
  setup() {
    
    const d = document;
    const id = 'google-auth2-script';

    // inject google sdk <script>
    if (d.getElementById(id)){ return; }
    var js = d.createElement('script');
    js.id = id;
    js.src = 'https://accounts.google.com/gsi/client';
    js.async = true;
    js.defer = 'defer';
    js.addEventListener('load', this.gsiLoaded);
    d.head.appendChild(js);

    return true;
  }


  gsiLoaded() {
    const googleAppId = '607669403759-nqjedvj018diansrb6kph89c75ppsp44.apps.googleusercontent.com'; // see .env files, replaced by rollup replace plugin

    google.accounts.id.initialize({
      client_id: googleAppId,
      callback: GoogleLoginClass.googleLoginResponse,
      context: 'signin', //signin="Sign in with Google",signup="Sign up with Google", use="Use with Google"
      // cancel_on_tap_outside: false,
      itp_support: true,
      use_fedcm_for_prompt: true,
      ux_mode: 'popup',
    });
    // use class to select multiple buttons if they exist
    const googleButtonContainers = document.querySelectorAll('.google-login-button-container');
    googleButtonContainers.forEach(c => {
      google.accounts.id.renderButton(c, {
        size: 'large',
        text: 'signin_with',
        width: 300,
      });
    });
  }


  getLoginPromise() {
    // setup Promise to be resolved in the callback `googleLoginResponse`
    if (!GoogleLoginClass.loginPromise) {
      GoogleLoginClass.loginPromise = new Promise((resolve,reject) => {
        GoogleLoginClass.loginResolver = resolve;
        GoogleLoginClass.loginRejecter = reject;
      });
    }
    return GoogleLoginClass.loginPromise;
  }
  
  login() {

    // setup Promise to be resolved in the callback `googleLoginResponse`
    const promise = this.getLoginPromise();

    // trigger the google popup
    google.accounts.id.prompt((notification) => {
      if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
        console.error('Google prompt not displayed! notification =', notification);
      }
    });

    console.log('GoogleLoginClass::login() - just called google.accounts.id.prompt()');

    return promise;

  } // login()


  /**
   * Handles the response from Google login.
   * @param {Object} credentialResponse - The response object containing user credentials and other data.
   */
  static googleLoginResponse(credentialResponse) {
    // see: https://developers.google.com/identity/gsi/web/reference/js-reference#CredentialResponse
    /*
      credential - JWT needs decoding. https://developers.google.com/identity/gsi/web/reference/js-reference#credential
      select_by - https://developers.google.com/identity/gsi/web/reference/js-reference#select_by
      state - This field is only defined when user clicks a Sign in with Google button to sign in, and the button's state attribute is specified.
    */

    this.credential = GoogleLoginClass.decodeJwtResponse(credentialResponse.credential);

    // console.log('GoogleLoginClass::googleLoginResponse() credential', this.credential);
    
    const googleSuccessEvent = new CustomEvent('googleLogin:processResults', {
      detail: this.credential,
    });

    document.dispatchEvent(googleSuccessEvent);

    if (GoogleLoginClass.loginPromise) {
      GoogleLoginClass.loginResolver(this.credential);
    }
    
  } // googleLoginResponse()

  static decodeJwtResponse(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  logout() {
    const GoogleAuth = gapi.auth2.getAuthInstance();
    return GoogleAuth.signOut() // returns a Promise that is fulfilled when the user has been signed out.
  }
}

const GoogleLogin = new GoogleLoginClass;/* src\facebook-login.js */

/**
 * Facebook login
 * 
 * using facebook login sdk
 *  - ~setup() - injects sdk if missing, sets up ids etc.
 *  - UI - facebook handles login UI
 *  - @returns
 *    - success / failure
 *    - promise
 *    - execute callback
 */

class FacebookLoginClass {

  setup() {

    const d = document;
    // const id = 'facebook-auth2-script'
    const id = 'facebook-jssdk'; // see https://developers.facebook.com/apps/365119583958775/analytics/quickstart/?business_id=1151533938294290

    if (d.getElementById(id)){ return; }

    const facebookAppId = '365119583958775'; // see .env files, replaced by rollup replace plugin

    window.fbAsyncInit = function() {
      FB.init({
        appId            : facebookAppId,
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v16.0'
      });
      // FB.AppEvents.logPageView();
    };

    // inject facebook sdk <script>
    // if (d.getElementById(id)){ return; } ⇑
    var js = d.createElement('script');
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    js.async = true;
    js.defer = 'defer';
    js.crossorigin = 'anonymous';
    d.head.appendChild(js);
    
    return true;
  } // setup()

  login() {
    return new Promise((resolve, reject) => {
      FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          FB.api('/me?fields=id,name,email', result => {
            const mergedResponse = Object.assign(response, { user: result });
            resolve(mergedResponse);
          });
        } else {
          FB.login((response) => {
            if (response.status === 'connected') {
              response.authResponse.userID;
              FB.api('/me?fields=id,name,email', result => {
                const mergedResponse = Object.assign(response, { user: result });
                resolve(mergedResponse);
              });
            } else {
              reject(response);
            }
          }, { scope: 'public_profile,email' });
        }
      });
    })
  } // login()

  logout() {
    return new Promise((resolve, reject) => {
      FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          FB.logout(response => {
            resolve(response);
          });
        } else {
          resolve('not logged in');
        }
      });
    })
  }
  
}

var FacebookLogin = new FacebookLoginClass;/* src\index.js */
// import AppleLogin from './apple-login'

const login = {

  setup() {
    if (typeof GoogleLogin !== 'undefined')   GoogleLogin.setup();
    if (typeof FacebookLogin !== 'undefined') FacebookLogin.setup();
    if (typeof AppleLogin !== 'undefined')    AppleLogin.setup();
    if (typeof ShopraiseLogin !== 'undefined')   ShopraiseLogin.setup();
  },

  login( type = 'shopraise', options = {}, callback ) {

    const haveCallback = (typeof callback !== 'undefined');

    return new Promise((resolve, reject) => {

      let result = {
        error: -1,
        msg: 'Unknown type',
      };
  
      switch (type) {
        case 'google':
          if (GoogleLogin) {
            // result = await GoogleLogin.login(options)
            GoogleLogin.login()
              .then(googleUser => {
                // call shopraise login
                console.log('shopraise-login::googleUser =', googleUser);
                const googleResult = {
                  id: googleUser.sub,
                  email: googleUser.email,
                  firstName: googleUser.given_name,
                  lastName: googleUser.family_name,
                };
                // console.log('google login result:', JSON.stringify(googleResult, undefined, 2))
                const params = (options.hasOwnProperty('keepLoggedIn') && options.keepLoggedIn /*is truthy*/)
                  ? { email: googleResult.email, googleId: googleResult.id, keepLoggedIn: options.keepLoggedIn }
                  : { email: googleResult.email, googleId: googleResult.id };
                return ShopraiseLogin.login(params)
              })
              .then((user) => {
                // console.log('GoogleLogin - 2nd then:: user =', user);
                if (haveCallback) callback(user); // or googleResult
                resolve(user); // or googleResult
              })
              .catch(error => {
                if (haveCallback) callback(error);
                reject(error);
              });
          } else {
            reject({ error: 1, msg: 'Google login not supported'});
          }
          break;
        
        case 'facebook':
          if (FacebookLogin) {
            // result = await FacebookLogin.login(options)
            FacebookLogin.login()
              .then(facebookUser => {
                // console.log('facebook login result:', facebookUser)
                const user = facebookUser.user;
                const params = (options.hasOwnProperty('keepLoggedIn') && options.keepLoggedIn /*is truthy*/)
                  ? { email: user.email, facebookId: user.id, keepLoggedIn: options.keepLoggedIn }
                  : { email: user.email, facebookId: user.id };
                return ShopraiseLogin.login(params)
              })
              .then(user => {
                // console.log('FacebookLogin - 2nd then(', user,')')
                if (haveCallback) callback(user);
                resolve(user);
              })
              .catch(err => {
                if (haveCallback) callback(err);
                reject(err);
              });
          } else {
            reject({ error: 1, msg: 'Facebook login not supported'});
          }
          break;
          
        case 'apple':
          if (AppleLogin) ; else {
            reject({ error: 1, msg: 'Apple login not supported'});
          }
          break;
          
        case 'shopraise':
          if (ShopraiseLogin) {
            const email = options.hasOwnProperty('email') ? options.email : '';
            const password = options.hasOwnProperty('password') ? options.password : '';
            const keepLoggedIn = options.hasOwnProperty('keepLoggedIn') ? options.keepLoggedIn : false;
            // if (password) {}
            const params = {email, password};
            if (keepLoggedIn) params['keepLoggedIn'] = keepLoggedIn;
            ShopraiseLogin.login(params)
              .then(result => {
                console.log('NativeLogin:: result =', result);
                resolve(result);
              })
              .catch(error => {
                console.error('NativeLogin:: error =', error);
                reject(error);
              });
          }
          // result = await NativeLogin.login(options)
          break;

        default:
          reject(result);
          break;
      
      } // switch on login type

    }) // new Promise

  }, // login()

  logout() {

    if (ShopraiseLogin) {
      return ShopraiseLogin.logout()
        .then(result => {
          // console.log('Logout::', result)
          return result
        })
        .catch(error => error)
    } else {
      return Promise.reject()
    }

  }, // logout()

  forgotPassword(email = null) {

    if (ShopraiseLogin) {
      return ShopraiseLogin.forgotPassword(email)
    } else {
      return Promise.reject()
    }

  }, // forgotPassword(email)

}; // login


document.addEventListener('googleLogin:processResults', e => {

  const googleUser = e.detail;

  const ckKeepLoggedIn = document.getElementById('ckKeepLoggedIn');
  const keepLoggedIn = ckKeepLoggedIn ? ckKeepLoggedIn.checked : null;
  
  const googleResult = {
    id: googleUser.sub,
    email: googleUser.email,
    firstName: googleUser.given_name,
    lastName: googleUser.family_name,
  };
  // console.log('google login result:', JSON.stringify(googleResult, undefined, 2))
  const params = (keepLoggedIn)
    ? { email: googleResult.email, googleId: googleResult.id, keepLoggedIn }
    : { email: googleResult.email, googleId: googleResult.id };
  const success = ShopraiseLogin.login(params);

  success.then(data => {

    const googleSuccessEvent = new CustomEvent('googleLogin:success', {
      detail: {
        shopraiseUser: data,
        googleUser: googleResult,
      }
    });

    document.dispatchEvent(googleSuccessEvent);

    if (GoogleLogin.loginPromise) {
      GoogleLogin.loginResolver({
        shopraiseUser: data,
        googleResult
      });
    }

  });

}); // googleLogin:processResults


if (typeof shopraise === 'undefined') var shopraise = {};
shopraise.login = login;
shopraise.login.setup(); // init the SDKs
export default shopraise;export{FacebookLogin,GoogleLogin,ShopraiseLogin as NativeLogin};